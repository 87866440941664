// src/App.js

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import ReactGa4 from 'react-ga4';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa'; // Import FaEnvelopeOpen
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

// Import your components
import SpotifySearchComponent from './components/SearchPage/SpotifySearch';
import QueueListPage from './components/venuePage/QueueList';
import VenuePlayer from './components/venuePage/VenuePlayer';
import PlaylistPage from './components/PlaylistPage/PlaylistPage';
import AboutUs from './components/ContactPage/AboutUs';
import FeedbackForm from './components/ContactPage/ContactUs';
import ErrorPage from './components/ErrorPage/ErrorPage';
import UserLogin from './components/auth/UserLogin';
import VenueLogin from './components/auth/VenueLogin';
import SpotifyPlayer from './components/utility/SpotifyPlayer';
// eslint-disable-next-line no-unused-vars
import FreezeMode from './components/FreezeMode'; // Import FreezeMode component
import AdminPage from './components/AdminControlPage/AdminPage'; // Adjust the import path if necessary

// Import assets
import muteflyHeader from './assets/mutefly-header.png';

// Import Menu Context
import { MenuProvider, useMenu } from './context/MenuContext';

// Import MenuIcon
import MenuIcon from './components/MenuIcon'; // Ensure this path is correct
import VenueAdminLogin from './components/auth/VenueAdminLogin';

// Initialize Google Analytics
ReactGa4.initialize('G-NQHQBJXK5S');

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'League Spartan', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scroll */
    background-color: #121212; /* Dark background for better contrast */
  }
`;

// Keyframe Animations

// FAB Reappearance Animation
const fabReappear = keyframes`
  0% {
    transform: scale(0) rotate(0deg);
  }
  20% {
    transform: scale(1.05) rotate(-10deg);
  }
  40% {
    transform: scale(1) rotate(10deg);
  }
  60% {
    transform: scale(1.05) rotate(-10deg);
  }
  80% {
    transform: scale(1) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
`;

// Styled Components

const Header = styled.header`
  top: 0;
  left: 50%;
  // right: 0;
  transform:translate(-50%,0);
  background-color: #141414;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 30px;
  z-index: 1000;
  width: 100%; // Adjust width based on menu state
  transition: transform 0.3s ease-in-out;// Smooth transition for the movement
  position: fixed;
  max-width:440px;
`;

// SideMenu and Overlay Components
const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background: #141414;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transform: ${(props) => (props.$show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
`;

const Overlay = styled.div`
  display: ${(props) => (props.$show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

// MenuItem from the first code
const MenuItem = styled.div`
  color: ${(props) => (props.$isActive ? '#FFBA33' : '#fff')};
  font-size: 18px;
  text-decoration: none;
  margin: 20px 20px 0px 20px;
  display: block;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 15%;
    width: ${(props) => (props.$isActive ? '35%' : '0')};
    height: 2px;
    background-color: #FFBA33;
    transition: width 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover:after {
    width: 50%;
  }

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

const FloatingActionButton = ({ onClick, hasPressedFAB }) => {
  return (
    <FABButton onClick={onClick} showDot={!hasPressedFAB} aria-label="Contact Us">
      {hasPressedFAB ? (
        <motion.div
          initial={{ rotateY: 0 }}
          animate={{ rotateY: 360 }}
          transition={{ duration: 0.5 }}
        >
          <FaEnvelopeOpen size={20} />
        </motion.div>
      ) : (
        <FaEnvelope size={20} />
      )}
    </FABButton>
  );
};

// Styled Components for FABButton with Green Dot
const FABButton = styled.button`
  position: relative; /* To position the pseudo-element relative to the button */
  background-color: #141414;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: ${fabReappear} 1s forwards;
  margin-right: 16px;

  /* Hover and Tap Effects */
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;

  &:active {
    transform: scale(0.9);
  }

  /* Green Dot Pseudo-Element */
  &::after {
    display: ${(props) => (props.showDot ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;
    background-color: #38E078; /* Green color */
    border-radius: 50%;
    margin-right: 3px;
    margin-top: 4px;
  }

  // @media (max-width: 400px) {
  //   width: 45px;
  //   height: 40px;
  //   margin-right: 32px;
  // }
`;

const PageWrapper = styled.div`
  animation: fadeIn 0.5s forwards;

  /* Define fadeIn animation */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

// Commented out RotatingMenu and related components
/*
const RotatingMenu = ({ menuOpen, toggleMenu, venueId }) => {
  // RotatingMenu component code...
};
*/

// SoundBar Component
const SoundBar = ({ venueId }) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    // Log GA event directly
    ReactGa4.event({
      category: 'Navigation',
      action: 'logo_tap',
      label: venueId,
    });

    const searchPath = `/search?venueId=${venueId}`;
    if (location.pathname === '/search') {
      window.location.reload();
    } else {
      history.push(searchPath);
    }
  };

  return (
    <StyledSoundBar onClick={handleClick} />
  );
};

const StyledSoundBar = styled.div`

  background: url(${muteflyHeader}) no-repeat center center;
  background-size: contain;
  border: none;
  margin-left: 14%;
  width: 70%;
  height: 40px;
  cursor: pointer;
  left: 0px;
`;

// AppContent Component with Page Transitions
const AppContent = ({ token, venueId, sessionId, setIsFreezeModeActive }) => {
  const location = useLocation();

  return (
    <Switch location={location} key={location.pathname}>
      <Route path="/search">
        <PageWrapper>
          <SpotifySearchComponent
            venueId={venueId}
            sessionId={sessionId}
          />
        </PageWrapper>
      </Route>
      <Route path="/queue">
        <PageWrapper>
          <QueueListPage venueId={venueId} />
        </PageWrapper>
      </Route>
      <Route path="/venue">
        <PageWrapper>
          <VenuePlayer venueId={venueId} />
        </PageWrapper>
      </Route>
      <Route path="/playlist">
        <PageWrapper>
          <PlaylistPage venueId={venueId} sessionId={sessionId} />
        </PageWrapper>
      </Route>
      <Route path="/aboutus">
        <PageWrapper>
          <AboutUs venueId={venueId} />
        </PageWrapper>
      </Route>
      <Route path="/error">
        <PageWrapper>
          <ErrorPage />
        </PageWrapper>
      </Route>
      <Route path="/player">
        <PageWrapper>
          <SpotifyPlayer venueId={venueId} />
        </PageWrapper>
      </Route>
      <Route path="/login">
        <PageWrapper>
          <UserLogin />
        </PageWrapper>
      </Route>
      <Route path="/adminconsole">
        <PageWrapper>
          {localStorage.getItem('adminToken') ? <AdminPage venueId={localStorage.getItem('venueId')} /> : <Redirect to="/admin" />}
          {/* <AdminPage /> */}
        </PageWrapper>
      </Route>
      <Route exact path="/admin">
        <PageWrapper>
          {localStorage.getItem('adminToken') ? <Redirect to={`/adminconsole?venueId=${localStorage.getItem('venueId')}`} /> : <VenueAdminLogin />}
          {/* <VenueAdminLogin/> */}
        </PageWrapper>
      </Route>
      <Route path="/venuelogin">
        <PageWrapper>
          {token ? <Redirect to={`/venue?venueId=${venueId}`} /> : <VenueLogin venueId={venueId} />}
        </PageWrapper>
      </Route>
      <Route path="/submitFeedback">
        <PageWrapper>
          <FeedbackForm venueId={venueId} />
        </PageWrapper>
      </Route>
      <Redirect from="/" to="/search" />
    </Switch>
  );
};

// ConditionalHeaderAndMenu Component
const ConditionalHeaderAndMenu = ({ venueId, isFreezeModeActive }) => {
  const location = useLocation();
  const { menuOpen, toggleMenu } = useMenu();
  const history = useHistory();

  const [hasPressedFAB, setHasPressedFAB] = useState(false);

  // Define paths where you want to hide the Header and Menu
  const pathsToHide = ['/queue', '/player', '/login', '/venue', '/venuelogin', '/admincontrol', '/admin', '/adminconsole'];

  // Manage body overflow
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpen]);

  // Initialize hasPressedFAB from sessionStorage
  useEffect(() => {
    const pressed = sessionStorage.getItem('hasPressedFAB');
    if (pressed === 'true') {
      setHasPressedFAB(true);
    }
  }, []);

  const handleFABClick = () => {
    ReactGa4.event({
      category: 'Contact Us',
      action: 'message_icon_tap',
      label: venueId,
    });

    if (menuOpen) {
      toggleMenu(); // Close the menu
      // Wait for the exit animation before navigating
      setTimeout(() => {
        history.push(`/submitFeedback?venueId=${venueId}`);
      }, 500); // Match the duration of the exit animation
    } else {
      history.push(`/submitFeedback?venueId=${venueId}`);
    }

    if (!hasPressedFAB) {
      setHasPressedFAB(true);
      sessionStorage.setItem('hasPressedFAB', 'true');
    }
  };

  if (pathsToHide.includes(location.pathname)) {
    return null; // Return null to render nothing
  }

  return (
    <>
      {/* SideMenu */}
      <SideMenu $show={menuOpen}>
        <MenuItemContainer>
          <MenuItemList>
            {[
              { name: 'Home', path: `/search?venueId=${venueId}` },
              { name: 'Live Playlist', path: `/playlist?venueId=${venueId}` },
              { name: 'Contact Us', path: `/submitFeedback?venueId=${venueId}` },
              { name: 'About Us', path: `/aboutus` },
            ].map((item) => (
              <MenuItem
                key={item.path}
                onClick={() => {
                  toggleMenu();
                  history.push(item.path);
                }}
                $isActive={location.pathname + location.search === item.path}
              >
                {item.name}
              </MenuItem>
            ))}
          </MenuItemList>
        </MenuItemContainer>
      </SideMenu>
      <Overlay $show={menuOpen} onClick={toggleMenu} />

      {/* Header */}
      <Header>
        <MenuIcon menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <SoundBar venueId={venueId} />
        {location.pathname !== '/submitFeedback' && (
          <FloatingActionButton onClick={handleFABClick} hasPressedFAB={hasPressedFAB} />
        )}
      </Header>
    </>
  );
};

// MenuItemContainer and MenuItemList for styling
const MenuItemContainer = styled.div`
  margin-top: 60px; /* To offset the header height */
  display: flex;
  flex-direction: column;
`;

const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

// App Component
const App = () => {
  const [venueId, setVenueId] = useState('default');
  const [token, setToken] = useState('');
  const [sessionId, setSessionId] = useState(null); // Track session ID

  useEffect(() => {
    // Check if there's an existing session ID in localStorage
    let existingSessionId = localStorage.getItem('session_id');

    // If no session ID exists, generate a new one
    if (!existingSessionId) {
      const newSessionId = uuidv4(); // Generate a unique ID
      localStorage.setItem('session_id', newSessionId); // Store the session ID
      setSessionId(newSessionId); // Update state
      console.log('New session ID generated:', newSessionId);
    } else {
      setSessionId(existingSessionId); // Use existing session ID
      console.log('Existing session ID found:', existingSessionId);
    }

    console.log('App component rendered or re-rendered');
    const params = new URLSearchParams(window.location.search);
    const newVenueId = params.get('venueId');
    setToken(params.get('access_token'));

    if (newVenueId && newVenueId !== venueId) {
      setVenueId(newVenueId);
    }
  }, [venueId]); // Added dependency to avoid missing updates

  return (
    <MenuProvider>
      <Router>
        <GlobalStyle />
        <ConditionalHeaderAndMenu venueId={venueId} />
        <AppContent
          token={token}
          venueId={venueId}
          sessionId={sessionId}
        />
      </Router>
    </MenuProvider>
  );
};

export default App;