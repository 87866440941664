// src/components/NowPlayingBar.js

import React, { useEffect, useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ControlButton } from './ControlButton'; // Adjust the import path
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NowPlayingBar = ({ song, onNextTrack }) => {
  const [rotation, setRotation] = useState(0);
  const [duration, setDuration] = useState(song?.songObj?.duration_ms || 0);
  const [currentTime, setCurrentTime] = useState(0);

  // Update current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(prevTime => {
        if (prevTime < duration) {
          return prevTime + 1000;
        } else {
          clearInterval(interval);
          return duration;
        }
      });
      setRotation(prevRotation => prevRotation + 0.5); // Adjust rotation speed
    }, 1000);

    return () => clearInterval(interval);
  }, [duration]);

  const percentage = duration ? (currentTime / duration) * 100 : 0;

  if (!song) {
    return null; // Don't render if no song is available
  }

  return (
    <BarContainer>
      <GlobalStyle />
      <AlbumArtContainer>
        <RotationWrapper rotation={rotation}>
          <AlbumArt src={song.songObj.album.images[0].url} alt="Album Art" />
        </RotationWrapper>
      </AlbumArtContainer>
      <SongInfo>
        <SongTitle>{song.songObj.name}</SongTitle>
        <ArtistName>{song.songObj.artists[0].name}</ArtistName>
      </SongInfo>
      <ActionArea>
        <ControlButton onClick={() => onNextTrack(0)}>
          {/* SVG Icon for Next Track */}
          <div style={{ width: '2.25rem', height: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256">
            <path d="M48 208.12V47.88a16 16 0 0124.43-13.43L192 109.23V40a8 8 0 0116 0v176a8 8 0 01-16 0v-69.23L72.43 221.55A15.95 15.95 0 0148 208.12z" />
          </svg>
          </div>
        </ControlButton>
      </ActionArea>
    </BarContainer>
  );
};

export default NowPlayingBar;

// Styled Components

const GlobalStyle = createGlobalStyle`
  svg {
    color: inherit; /* Ensure SVG inherits color */
    fill: currentColor; /* Use current color for fill */
    width: 24px; /* Set a default width */
    height: 24px; /* Set a default height */
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance: none) {
      svg {
        fill: currentColor; /* Ensure color is applied in Safari */
      }
    }
  }
`;

const BarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform:translate(-50%,0);
  width: calc(100% - 14px);
  max-width:480px;
  background-color: #1E1E1E;
  display: flex;
  align-items: center;
  padding: 20px 16px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding-left: 0px;
  border-top-left-radius: 16px; 
  border-top-right-radius: 16px;
  margin: 0 auto; /* Center the container */
`;

const AlbumArtContainer = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  padding-left: 16px;
  margin-left: 8px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotationWrapper = styled.div`
  width: 100%;
  height: 100%;
  // animation: ${rotate} 20s linear infinite;
  // transform: rotate(${props => props.rotation}deg);
`;

const AlbumArt = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 16px;
`;

const SongInfo = styled.div`
  flex-grow: 1;
  margin-left: 16px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  max-width:50%;
`;

const SongTitle = styled.span`
  color: #fff;
  font-size: 16px;
  max-width: 100%;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const ArtistName = styled.span`
  color: #9ea3b8;
  font-size: 14px;
  margin: 0;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;