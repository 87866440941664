// src/components/venuePage/SkipReason.js

import React, { useState } from 'react';
import styled from "styled-components";

// Define skip reasons with corresponding emojis
const skipReasons = [
  { id: 'too_loud', label: 'Too Loud', emoji: '🔊' },
  { id: 'already_heard', label: 'Already Heard', emoji: '🔄' },
  { id: 'inappropriate_content', label: 'Inappropriate Content', emoji: '🚫' },
  { id: 'not_suitable', label: 'Not Suitable for Venue', emoji: '👎' },
  { id: 'other', label: 'Other', emoji: '🤷‍♂️' },
];

// Styled Components Overlay for Dialog
const Overlay = styled.div`
  display: ${({ isDialogOpen }) => (isDialogOpen ? 'flex' : 'none')};
  position: fixed; /* Use fixed positioning */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 23, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

// Dialog Box Styling
const DialogContainer = styled.div`
  background-color: #2E2E2E;
  padding: 2rem;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 500px;
  color: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index:1001;
`;

const DialogOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 23, 0.9); // Slightly transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; // Ensure it overlays other elements
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmojiOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
`;

const EmojiButton = styled.button`
  background: ${({ selected }) => (selected ? '#38E078' : 'transparent')};
  border: 2px solid ${({ selected }) => (selected ? '#38E078' : '#FFFFFF')};
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
  &:hover {
    background: #38E078;
    border-color: #38E078;
  }
`;

const OtherReasonInput = styled.textarea`
  width: 100%;
  height: 80px;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  resize: none;
  font-size: 1rem;
  background-color: #1E1E1E;
  color: #FFFFFF;
  outline: none;
  margin-top: 1rem;
  &:focus {
    background-color: #2E2E2E;
  }
`;

const DialogButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const SubmitButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? '#555555' : '#38E078')};
  color: #FFFFFF;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#555555' : '#30C066')};
  }
`;

const CancelButton = styled.button`
  background-color: #FF4D4D;
  color: #FFFFFF;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #FF1A1A;
  }
`;

// SkipReason Component
const SkipReason = ({ isOpen, onClose, onSubmit }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');

    const handleEmojiClick = (reasonId) => {
      setSelectedReason(reasonId);
      if (reasonId !== 'other') {
        setOtherReason('');
      }
    };

    const handleSubmit = () => {
      if (selectedReason && onSubmit ) {
        const reasonText =
          selectedReason === 'other'
            ? otherReason.trim()
            : skipReasons.find((r) => r.id === selectedReason).label;
        onSubmit(reasonText);
        // Reset the form after submission
        setSelectedReason('');
        setOtherReason('');
        onClose(); // Close the dialog
      }
    };

    const wordCount = otherReason
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
    const isSubmitEnabled =
      selectedReason &&
      (selectedReason !== 'other' ||
        (selectedReason === 'other' && wordCount >= 3 && wordCount <= 100));

    return (
      <Overlay isDialogOpen={isOpen}>
        <DialogOverlay>
        <DialogContainer>
          <h3>Why are you skipping this song?</h3>
          <FormContainer>
            <EmojiOptions>
              {skipReasons.map((reason) => (
                <EmojiButton
                  key={reason.id}
                  onClick={() => handleEmojiClick(reason.id)}
                  selected={selectedReason === reason.id}
                  aria-label={reason.label}
                  title={reason.label}
                >
                  {reason.emoji}
                </EmojiButton>
              ))}
            </EmojiOptions>
            {selectedReason === 'other' && (
              <>
                <OtherReasonInput
                  placeholder="Please specify your reason ..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
                <p>Word Count: {wordCount} (3-50 words)</p>
              </>
            )}
            <DialogButtons>
              <CancelButton
                onClick={() => {
                  setSelectedReason('');
                  setOtherReason('');
                  onClose();  // Close the dialog without triggering any submission logic
                }}
              >
                Cancel
              </CancelButton>
              <SubmitButton onClick={handleSubmit} disabled={!isSubmitEnabled}>
                Submit
              </SubmitButton>
            </DialogButtons>
          </FormContainer>
        </DialogContainer>
        </DialogOverlay>
      </Overlay>
    );
  };

  export default SkipReason;