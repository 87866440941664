// src/components/ControlButton.js

import styled from 'styled-components';

export const ControlButton = styled.button`
  background-color: ${props => (props.active ? 'transparent' : 'transparent')};
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: ${props => (props.active ? '#FFFFFF' : '#38E078')};
  font-size: 1.5rem;
  margin-right: 16px;
  margin-bottom: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s ease, color 0.3s ease;

  &:hover {
    color: #FF4D4D; /* Change color on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
  &:active {
    transform: scale(0.95); /* Slightly shrink on click */
    color: #FF4D4D; /* Change to red when pressed */
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    color: #38E078;
    transition: color 0.3s ease;
  }
`;

export default ControlButton;

