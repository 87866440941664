import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import muteflyPlayerBg from '../../assets/mutefly-player-bg.png'; // Adjust the path to your actual image location
import Lottie from 'react-lottie';
import birdFly from '../../animations/birdflygreen.json';
import loaderGreen from '../../animations/loadergreen.json';
import greenBlob from '../../animations/greenblob.json';

const Loader = styled.div`
  display: none; // Hide on devices wider than 768px

  @media (max-width: 768px) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Make sure it covers other content
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.8s ease-in-out;
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media(max-width: 768px){
    background-color: #121217;
    background-image: none;
  }

  @media(min-width:768px){
  background-image: url(${muteflyPlayerBg});
  }
`;

const AnimationOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;  // Allows clicks to pass through
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;

  @media (max-width: 768px) {
    width: 100vw;  // Take full width on mobile devices
    padding: 20px; // Add padding for smaller screens
    box-sizing: border-box; // Include padding in width calculation
  }
`;

const RightContainer = styled.div`
  width: 50vw;
  height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FrostedGlass = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #00000069;
  border-radius: 26px;
  padding: 2rem;
  width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: height 0.5s ease;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;  // Make full width on mobile
    margin-bottom: 0rem;  // Add a large margin-bottom to ensure space for the fixed button
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Center align the text horizontally
  padding: 0 5%; // Use percentage for dynamic padding
  width: 90%; // Adjust the width to account for padding
  border-bottom: 1px solid #fff; // White border underneath
  margin: 0 auto; // Center the logo div horizontally

  svg {
    width: 32px;
    height: 32px;
  }

  .logo-text {
    font-size: 1.31rem;
    font-weight: bold;
    color: #FFFFFF; // Default color for the "Mutefly" part
  }

  .player-text {
    font-size: 1.44rem;
    color: #38E078; // Green color for the "Player" part
    font-weight: lighter; // Thinner font-weight
  }
`;

const InnerContainer = styled.div`
  padding: 3rem;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.5s ease, opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transform: ${props => (props.isActive ? 'translateX(0)' : props.direction === 'left' ? 'translateX(-100%)' : 'translateX(100%)')};
  position: ${props => (props.isActive ? 'relative' : 'absolute')};
  height: auto;
  top: 0;
  left: 0;

  @media( max-width:768px){
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 36px;
  }
`;

const Header = styled.h1`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
`;

const DescriptionText = styled.p`
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;

  @media(max-width:768px){
  margin-bottom:1.5rem;
  margin-top:0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #fff;
  margin: 1rem 0;
  margin-top: 2rem;

  @media(max-width:768px){
  margin-top:1rem;
  margin-bottom:0;
  }
`;

const BlobLottieWrapper = styled.div`
  padding-top: 20px;  // Adjust the top padding as needed
  padding-bottom: 30px;  // Adjust the bottom padding as needed
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prefix = styled.span`
  color: #fff;
  font-size: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 0.4rem;
`;

const InputField = styled.input`
  flex-grow: 1;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  outline: none;
  ::placeholder {
    color: #ccc;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;  // Slightly smaller font size for mobile
  }
`;

const PinInputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const PinInput = styled.input`
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 1.5rem;
  text-align: center;
  border: none;
  border-bottom: 2px solid #fff;
  background: transparent;
  color: #fff;
  outline: none;
  ::placeholder {
    color: #ccc;
  }

  @media (max-width: 768px) {
    width: 100%;  // Slightly smaller font size for mobile
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const ActionButton = styled.button`
  width: calc(100% - 2rem);
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  border: none;
  border-radius: 25px;
  background-color: #34c759;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #28a745;
  }

  @media (max-width: 768px) {
    position: fixed; // Fix the button at the bottom of the viewport
    bottom: 1rem; // Distance from the bottom
    left: 50%; // Center the button horizontally
    transform: translateX(-50%); // Adjust translation to truly center the button
    width: calc(100% - 2rem); // Adjust width to account for padding\
    margin: 0; // Remove margin
    border-radius: 20px; // Adjust border-radius if needed
  }
`;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const isValidPhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Validates 10-digit numbers starting with 6-9
    return phoneRegex.test(number);
};

const getStoredPhoneNumbers = () => {
    const storedNumbers = localStorage.getItem('phoneNumbers');
    return storedNumbers ? JSON.parse(storedNumbers) : [];
};

const VenueAdminLogin = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showLogin, setShowLogin] = useState(false);
    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const [loginStatus, setLoginStatus] = useState('number');
    const [venueName, setVenueName] = useState('');
    const [venuePin, setVenuePin] = useState('');
    const [loading, setLoading] = useState(true);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3 * 1000); // Loader shows for 3 seconds
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Get stored phone numbers from localStorage when the component mounts
        const storedPhoneNumbers = getStoredPhoneNumbers();
        setSuggestions(storedPhoneNumbers);

        // Pre-fill the input box with the last used phone number
        if (storedPhoneNumbers.length > 0) {
            setPhoneNumber(storedPhoneNumbers[0]);
        }
    }, []);

    useEffect(() => {
        // This effect runs every time the showLogin state changes
        console.log('showLogin state:', showLogin);
        console.log('loginStatus state:', loginStatus);
    }, [showLogin, loginStatus]);

    const savePhoneNumberToLocalStorage = (newNumber) => {
        const storedNumbers = getStoredPhoneNumbers();
        // Avoid duplicates in the list
        if (!storedNumbers.includes(newNumber)) {
            storedNumbers.unshift(newNumber); // Add new number to the beginning of the list
        }
        localStorage.setItem('phoneNumbers', JSON.stringify(storedNumbers));
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        setErrorMessage(''); // Clear error message on input change
        // Show suggestions only when there's input
        if (e.target.value) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handlePinChange = (e) => {
        setPin(e.target.value);
        setErrorMessage(''); // Clear error message on input change
    };

    const handleSuggestionClick = (number) => {
        setPhoneNumber(number);
        setShowSuggestions(false);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phoneNumber)) {
            setErrorMessage('Invalid phone number. Please enter a valid phone number.');
            return;
        }

        if (!phoneNumber || !pin) {
            setErrorMessage('Please enter your phone number and PIN');
            return;
        }
        savePhoneNumberToLocalStorage(phoneNumber);

        try {
            // Make backend call to validate the PIN
            const response = await axios.post(`${backendUrl}/api/admin/login`, {
              phoneNumber: phoneNumber,
              pin: pin
            });
        
            // If login is successful
            if (response.status === 200) {
              // Save some login state (if needed, like a token or session ID)
              localStorage.setItem('adminToken', response.data.token);
              console.log("token rcvd: "+response.data.token);

              const venueId = response.data.venueId;
              localStorage.setItem('venueId', response.data.venueId);
              
              // Navigate to /adminconsole
              history.push(`/adminconsole?venueId=${venueId}`);
            } else {
                setErrorMessage('Invalid login credentials');
            }
          } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage('Login failed. Please try again in sometime.');
          }
    };

    const handlePinSubmit = () => {
        const enteredPin = pin.join('');
        if (enteredPin === venuePin) {
            // Move to the next page or perform the next action
            console.log('Pin matched, proceed to the next page');
            setLoginStatus('spotify');
            // Add your navigation or next steps here
        } else {
            console.log(venuePin + " b " + enteredPin);
            setErrorMessage('Incorrect pin. Please try again.');
        }
    };

    return (
        <>
            {loading &&
                <Loader show={loading}>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: loaderGreen,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }} height={200} width={200} />
                </Loader>}
            <BackgroundContainer>
                <AnimationOverlay>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: birdFly,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height="100%"
                        width="100%"
                    />
                </AnimationOverlay>
                <LeftContainer>
                    <FrostedGlass>
                        <Logo>
                            <h2 className="logo-text">Mutefly.</h2><h2 className="player-text">Admin</h2>
                        </Logo>
                        <InnerContainer isActive={loginStatus === 'number'} direction="left">
                            <Header>Lets Get Started.</Header>
                            <InputContainer>
                                <Prefix>+91 -</Prefix>
                                <InputField
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            </InputContainer>
                            {/* <PinInputContainer>
                                {pin.map((digit, index) => (
                                    <PinInput
                                        key={index}
                                        id={`pin-${index}`}
                                        type="password"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handlePinChange(e, index)}
                                        onKeyDown={(e) => handlePinKeyDown(e, index)}
                                        inputMode="numeric"
                                    />
                                ))}
                            </PinInputContainer> */}
                            {showSuggestions && suggestions.length > 0 && (
                                    <ul style={{ listStyleType: 'none', padding: 0, border: '1px solid #ccc', marginTop: '0px', width: '100%' }}>
                                        {suggestions.map((number, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSuggestionClick(number)}
                                                style={{ padding: '10px', cursor: 'pointer', background: '#f9f9f9', borderBottom: '2px solid #eee', left: '10px' }}
                                            >
                                                {number}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                            <InputContainer>
                                <InputField
                                    type="password"
                                    placeholder="Enter PIN"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    onChange={handlePinChange}
                                />
                            </InputContainer>
                            <BlobLottieWrapper>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: greenBlob,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}
                                    height="70%"
                                    width="70%"
                                />
                            </BlobLottieWrapper>
                            <ActionButton onClick={handleLogin}>LOGIN</ActionButton>
                        </InnerContainer>

                        {/* <InnerContainer isActive={loginStatus === 'pin'} direction="right">
                            <Header >Lets Get Started.</Header>
                            <DescriptionText>Enter your 6 digit secure login pin</DescriptionText>
                            <PinInputContainer>
                                {pin.map((digit, index) => (
                                    <PinInput
                                        key={index}
                                        id={`pin-${index}`}
                                        type="password"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handlePinChange(e, index)}
                                        onKeyDown={(e) => handlePinKeyDown(e, index)}
                                        inputMode="numeric"
                                    />
                                ))}
                            </PinInputContainer>
                            <BlobLottieWrapper>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: greenBlob,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}
                                    height="70%"
                                    width="70%"
                                />
                            </BlobLottieWrapper>
                            <ActionButton onClick={handlePinSubmit}>Login</ActionButton>
                        </InnerContainer> */}
                    </FrostedGlass>
                </LeftContainer>
                <RightContainer />
            </BackgroundContainer>
        </>
    );
};

export default VenueAdminLogin;
