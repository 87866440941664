// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBQ-OHXs8jtQsOiL5tstZ20Z7yD4LfiV5E",
    authDomain: "scanthevibev1-6d5c5.firebaseapp.com",
    databaseURL: "https://scanthevibev1-6d5c5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "scanthevibev1-6d5c5",
    storageBucket: "scanthevibev1-6d5c5.appspot.com",
    messagingSenderId: "657785153961",
    appId: "1:657785153961:web:8badeb1e238108d3293cb8",
    measurementId: "G-PC64VY5FMB"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export auth instance to use in other parts of the app
export const auth = getAuth(app);