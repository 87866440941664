// src/components/HeartIconWithCount.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactGA from "react-ga4";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Styled Components
const HeartContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.isInteractive ? 'pointer' : 'default')};
  margin-right: 12px;
`;

const LikeCountCircle = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: white; /* White background for the circle */
  color: black; /* Black text */
  width: 18px; /* Adjust size as needed */
  height: 18px;
  border-radius: 50%;
  border: 2px solid #1E1E1E; /* Black border around the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px; /* Adjust font size based on your design */
  font-weight: bold;
`;

const HeartIconSVG = styled.svg`
  width: 25px;
  height: 25px;
  fill: ${(props) => (props.isFilled ? "#FF4C4C" : "none")};
  stroke: ${(props) => (props.isFilled ? "none" : "white")}; /* Remove border when filled */
  stroke-width: 2px;
  transition: fill 0.3s ease, stroke 0.3s ease;
`;

const HeartIconWithCount = ({ venueId, entryId, initialLikes, sessionId, isInteractive = true }) => {
  const [isFilled, setIsFilled] = useState(false); // Manage heart state
  const [likeCount, setLikeCount] = useState(initialLikes); // Initialize with likes from the backend

  useEffect(() => {
    if (isInteractive) {
      // Check if the current song has been liked in the current session
      const likedSongs = JSON.parse(localStorage.getItem(`likes_session_${sessionId}`)) || [];
      setIsFilled(likedSongs.includes(entryId));
    } else {
      // In read-only mode, set filled based on likeCount
      setIsFilled(likeCount > 0);
    }
  }, [entryId, sessionId, isInteractive, likeCount]);
   
  // **Add this new useEffect to update likeCount when initialLikes changes**
  useEffect(() => {
    setLikeCount(initialLikes);
  }, [initialLikes]);
  
  useEffect(() => {
    if (isInteractive) {
      // Set up polling to fetch the latest like count every 5 seconds
      const fetchLikeCount = async () => {
        try {
          const response = await axios.get(`${backendUrl}/queue/likes/${venueId}/${entryId}`);
          if (response.status === 200) {
            setLikeCount(response.data.likes);
          }
        } catch (error) {
          console.error("Error fetching like count:", error);
        }
      };

      // Initial fetch
      fetchLikeCount();

      const intervalId = setInterval(fetchLikeCount, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [venueId, entryId, isInteractive]);

  const toggleHeart = async () => {
    if (!isInteractive) return; // Do nothing in read-only mode

    ReactGA.event('pp_likes_tap', {
      category: "User",
      action: "pp_likes_tap",
      label: venueId,
      song_entryid: entryId,
    });

    try {
      const action = isFilled ? 'decrement' : 'increment';

      // Make API call to update like count
      const response = await axios.post(`${backendUrl}/queue/like/${venueId}/${entryId}`, { action });

      if (response.status === 200) {
        setLikeCount(response.data.song.likes);
        setIsFilled(!isFilled);

        // Store liked songs in localStorage with sessionId
        let likedSongs = JSON.parse(localStorage.getItem(`likes_session_${sessionId}`)) || [];

        if (!isFilled) {
          // Add the song to the liked songs array
          likedSongs.push(entryId);
        } else {
          // Remove the song from liked songs array if unliked
          likedSongs = likedSongs.filter(id => id !== entryId);
        }

        // Update localStorage with the new liked songs list
        localStorage.setItem(`likes_session_${sessionId}`, JSON.stringify(likedSongs));
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  return (
    <HeartContainer onClick={toggleHeart} isInteractive={isInteractive}>
      {/* Heart Icon */}
      <HeartIconSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" isFilled={isFilled}>
        <path d="M12 21.35C12 21.35 2 13.87 2 7.5C2 4.42 4.42 2 7.5 2C9.24 2 10.91 2.81 12 4.09C13.09 2.81 14.76 2 16.5 2C19.58 2 22 4.42 22 7.5C22 13.87 12 21.35 12 21.35Z" />
      </HeartIconSVG>

      {/* Like Count Circle */}
      {likeCount > 0 && (
        <LikeCountCircle>
          {likeCount}
        </LikeCountCircle>
      )}
    </HeartContainer>
  );
};

// PropTypes for type checking
HeartIconWithCount.propTypes = {
  venueId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  initialLikes: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool, // Defaults to true
};

export default React.memo(HeartIconWithCount);
