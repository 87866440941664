// src/pages/AdminPage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import styled, { keyframes } from "styled-components";
import ReactGA from "react-ga4";
import Lottie from 'react-lottie';
import { FaPowerOff, FaUserCircle } from 'react-icons/fa';

import { useHistory } from "react-router-dom";
import { FaTrash } from 'react-icons/fa'; // Import trash icon for skip button

// Import the Lottie Player and the updated animations
import { Player } from '@lottiefiles/react-lottie-player';
import calmBlob from "../../animations/calmblob.json";
import orangeBlob from '../../animations/orangeblob.json';
import greenBlob from '../../animations/greenblob.json';

// Import HeartIconWithCount and SkipReason components
import HeartIconWithCount from '../PlaylistPage/HeartIconWithCount'; // Adjust the import path if necessary
import SkipReason from '../venuePage/SkipReason'; // Adjust the import path if necessary
import NowPlayingBar from './NowPlayingBar'; // Import NowPlayingBar

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Styled Components
const AppWrapper = styled.div`
  background-color: #141414;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  height: 100vh;
  overflow: hidden;
`;

const ProfileContainer = styled.div`
  position: absolute;
  right: 1rem;
`;

const ProfileIcon = styled(FaUserCircle)`
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 1rem;

  &:hover {
    // color: #38E078; // Change to a greenish tint on hover
  }
`;

const ProfileMenu = styled.div`
  position: absolute;
  top: 60px; // Adjust so the menu appears below the icon
  right: 0rem;
  background-color: #121217;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  width: 180px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')}; // Hide or show based on state
  z-index: 100;

  .menu-header {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 1rem;
    text-align: center;
  }

  .menu-option {
    font-size: 0.9rem;
    color: #ff4d4d;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem 0;

    &:hover {
      background-color: #1f1f1f;
      border-radius: 5px;
    }
  }
`;


const Header = styled.header` 
  position: fixed;
  top: 0;
  left: 50%;
  transform:translate(-50%,0);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  padding-bottom: 1rem;
  background-color: #121217;
  z-index: 10;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 480px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .logo-text {
    font-size: 1.8rem;
    font-weight: bold;
    color: #FFFFFF;
  }

  .admin-text {
    font-size: 1.94rem;
    color: #38E078;
    font-weight: lighter;
  }
`;

const PageContainer = styled.div`
  background-color: #141414;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 72px;
  box-sizing: border-box;
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  position: fixed;
  background-color: #141414;
  top: 84px;
  width: 100%;
  max-width:480px;
  box-sizing: border-box;
`;

const Breadcrumb = styled.div`
  display: flex;
  flex-grow: 1;
  color: #9ea3b8;
  gap: 10px;
  flex-direction: column;
`;

const BlobLottieWrapper = styled.div`
  width: 75px;
  height: 75px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubheaderText = styled.span`
  color: #fff;
  font-size: 14px;
  margin: 0px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const HeaderText = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.5px;
  margin: 0px;
`;

const ContentContainer = styled.main`
  width: auto;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 121px;
  margin-bottom: 121px;
  padding: 20px;
  padding-top: 0px;
  background-color: #1E1E1E;
  border-radius: 16px;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const QueueSection = styled.section`
  margin-top: 12px;
  width: 100%;
  overflow-x: hidden;
  max-width: 100%;
  box-sizing: border-box; 
`;

const SongItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
`;

const SongDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 70%;
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70%;
`;

const SongTitle = styled.h3`
  color: #fff;
  font-size: 16px;
  max-width: 100%;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SongDuration = styled.p`
  color: #9ea3b8;
  font-size: 12px;
  margin: 0;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SongImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 16px;
`;

const ActionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SkipButton = styled.button`
  background: none;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: #FF0000;
  }
`;

// SongList Component
const SongList = ({ queue, onSkip, venueId, sessionId }) => {
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);

  const handleSkipClick = (index) => {
    setSelectedSongIndex(index);
    setIsSkipDialogOpen(true);
  };

  const handleSubmitSkipReason = async (reason) => {
    if (selectedSongIndex !== null) {
      await onSkip(selectedSongIndex, reason);
    }
    setIsSkipDialogOpen(false);
    setSelectedSongIndex(null);
  };

  return (
    <>
      {queue.slice(1).map((song, index) => (
        <SongItem key={song.entryId}>
          <SongDetails>
            <SongImage src={song.songObj.album.images[0].url} alt="Song Thumbnail" />
            <SongInfo>
              <SongTitle>{song.songObj.name}</SongTitle>
              <SongDuration>{song.songObj.artists[0].name}</SongDuration>
            </SongInfo>
          </SongDetails>
          <ActionArea>
            <HeartIconWithCount
              venueId={venueId}
              entryId={song.entryId}
              initialLikes={song.likesCount || 0}
              sessionId={sessionId}
              isInteractive={false}
            />
            {/* Only show SkipButton for the first song in the sliced queue (original index 1) */}
            {/* {index === 0 && ( */}
            <SkipButton onClick={() => handleSkipClick(index + 1)}>
              <FaTrash />
            </SkipButton>


          </ActionArea>
        </SongItem>
      ))}
      {isSkipDialogOpen && (
        <SkipReason
          isOpen={isSkipDialogOpen}
          onClose={() => setIsSkipDialogOpen(false)}
          onSubmit={handleSubmitSkipReason}
        />
      )}
    </>
  );
};

// AdminPage Component
const AdminPage = ({ venueId, sessionId }) => {
  const history = useHistory();
  const [venueName, setVenueName] = useState('The Venue');
  const [songQueueData, setSongQueueData] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userName, setUserName] = useState('Admin'); // Replace with the dynamic user name

  // Refs to track venueId and queueIndex
  const venueIdRef = useRef(venueId);
  const queueIndexRef = useRef(0);
  const tokenRefreshedRef = useRef(false);

  const handleProfileClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu
  };

  useEffect(() => {
    const fetchVenueName = () => {
      axios.get(`${backendUrl}/api/venue/name/${venueId}`)
        .then(response => {
          setVenueName(response.data.venueName);
        })
        .catch(error => {
          console.error('Error fetching venue name:', error);
        });
    };

    const fetchUserName = () => {
      axios.get(`${backendUrl}/api/venue/name/${venueId}`)
        .then(response => {
          setVenueName(response.data.venueName);
        })
        .catch(error => {
          console.error('Error fetching venue name:', error);
        });
    }

    venueId = localStorage.getItem('venueId');

    if (venueId) {
      fetchVenueName();
    }
  }, [venueId]);

  const fetchSongQueueData = () => {
    axios.get(`${backendUrl}/queue/${venueId}`)
      .then(response => {
        setSongQueueData(response.data);
        if (response.data.length === 1) {
          // Optionally handle recommendation or radio fetch
        }
      })
      .catch(error => {
        console.error('Error fetching song queue:', error);
      });
  };

  useEffect(() => {
    if (!venueId) {
      console.error('No venueId found in the URL');
      window.location.href = '/venuelogin';
    }

    fetchSongQueueData();
    const intervalId = setInterval(fetchSongQueueData, 5000);
    return () => clearInterval(intervalId);
  }, [venueId]);

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('venueId');
    history.push(`/admin`);
  };

  const handleSkipSong = async (index, reason = null) => {
    if (index !== null) {
      try {
        await axios.post(`${backendUrl}/queue/remove/${venueId}`, {
          index: index,
          reason: reason,
        });
        // Fetch the updated queue from the backend
        fetchSongQueueData();
      } catch (error) {
        console.error('Error submitting skip reason:', error);
      }
    }
  };

  const handleVenueNameTap = () => {
    ReactGA.event('ap_venue_name_tap', {
      category: "User",
      action: "ap_venue_name_tap",
      label: venueId,
    });
  };

  return (
    <AppWrapper>
      <Header>
        <Logo>
          <h2 className="logo-text">Mutefly.</h2>
          <h2 className="admin-text">Admin</h2>
        </Logo>
        <ProfileContainer>
          <ProfileIcon onClick={handleProfileClick} />
          <ProfileMenu isOpen={isMenuOpen}>
            <div className="menu-header">Hi, {userName}</div>
            <div className="menu-option" onClick={handleLogout}>Logout</div>
          </ProfileMenu>
        </ProfileContainer>
      </Header>
      <PageContainer>
        <TitleContainer>
          <BlobLottieWrapper>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: greenBlob,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={75}
              width={75}
            />
          </BlobLottieWrapper>
          <Breadcrumb onClick={handleVenueNameTap}>
            <SubheaderText>Playing Now at</SubheaderText>
            <HeaderText>{venueName}</HeaderText>
          </Breadcrumb>
        </TitleContainer>
        <ContentContainer>
          <QueueSection>
            <SongList
              queue={songQueueData}
              onSkip={handleSkipSong}
              venueId={venueId}
              sessionId={sessionId}
            />
          </QueueSection>
        </ContentContainer>
      </PageContainer>
      {songQueueData.length > 0 && (
        <NowPlayingBar
          song={songQueueData[0]}
          onNextTrack={handleSkipSong}
        />
      )}
    </AppWrapper>
  );
};

export default AdminPage;
