import React, { useState } from 'react';
import styled from 'styled-components';
import { auth } from '../../firebase';  // Firebase initialization
import { RecaptchaVerifier, PhoneAuthProvider, signInWithCredential, signInWithPhoneNumber } from 'firebase/auth';

// Container for the whole login page
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

// Box to wrap the login form
const LoginBox = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
`;

// Styled input fields
const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0 20px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  &:focus {
    border-color: #FFBA33;
  }
`;

// Styled label for input fields
const Label = styled.label`
  font-size: 14px;
  color: #555;
  text-align: left;
  width: 100%;
  display: block;
  margin-bottom: 8px;
`;

// Styled button
const Button = styled.button`
  background-color: #FFBA33;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #d69728;
  }
  
  &:focus {
    outline: none;
  }
`;


const UserLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [showOtpInput, setShowOtpInput] = useState(false);

  const sendOtp = () => {
    auth.settings.appVerificationDisabledForTesting = true;
    // Ensure that Firebase auth is initialized and passed correctly
    const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',  // You can also use size 'normal' for visible ReCAPTCHA
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('Recaptcha solved, OTP can be sent now.');
      },
    }, auth);  // <-- Ensure auth is passed here
  
    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setShowOtpInput(true);
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
        alert('Failed to send OTP, please try again.');
      });
  };
   // Your verifyOtp function
const verifyOtp = () => {
    const credential = PhoneAuthProvider.credential(verificationId, otp);  // Corrected
  
    signInWithCredential(auth, credential)  // Corrected
      .then(() => {
        alert('Successfully logged in!');
        localStorage.setItem('isLoggedIn', true);
        // Redirect or update UI as necessary
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        alert('Invalid OTP, please try again.');
      });
  };

  return (
    <LoginContainer>
      <LoginBox>
        <h2>Login with OTP</h2>
        <div id="recaptcha-container"></div>

        {!showOtpInput ? (
          <>
            <Label>Enter Mobile Number:</Label>
            <Input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="+91 1234567890"
              required
            />
            <Button onClick={sendOtp}>Send OTP</Button>
          </>
        ) : (
          <>
            <Label>Enter OTP:</Label>
            <Input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required
            />
            <Button onClick={verifyOtp}>Verify OTP</Button>
          </>
        )}
      </LoginBox>
        <div id="recaptcha-container"></div>

    </LoginContainer>
  );
};

export default UserLogin;
